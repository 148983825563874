<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row class="mb-2">
          <b-col cols="12">
            <h2 class="font-weight-bolder">
              {{ title }}
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="hasExport || searchEnabled"
            cols="12"
            lg="12"
            :class="`mb-2 d-flex flex-row  align-items-center ${
              hasExport ? 'justify-content-between' : ' justify-content-end'
            }`"
          >
            <div v-if="hasExport" class="d-flex flex-row align-items-end w-50">
              <b-dropdown
                split
                variant="outline-danger"
                menu-class="w-100"
                class="w-30 mr-1"
                :disabled="exporting"
              >
                <template #button-content>
                  <b-spinner v-if="exporting" class="mr-50 p-0" small />
                  <feather-icon v-else icon="DownloadIcon" class="mr-50" />
                  Export
                </template>
                <b-dropdown-item
                  v-for="(type, index) in exportType"
                  :key="index"
                  @click.prevent="$emit('handleExport', type.value)"
                >
                  {{ type.text }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-if="searchEnabled" class="w-30">
              <b-input-group>
                <b-form-input v-model="search" placeholder="Search" />
                <b-input-group-append>
                  <b-input-group-text class="bg-primary text-white">
                    Search
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped"
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: paginationEnabled,
                perPage: pageLength,
              }"
              :search-options="{
                enable: searchEnabled,
                externalQuery: search,
              }"
              :sort-options="{
                enable: true,
              }"
            >
              <div slot="emptystate" class="text-center">
                <span v-if="!isLoading">No Data Found</span>
                <b-spinner v-else variant="primary" />
              </div>
              <template slot="table-row" slot-scope="props">
                <slot :props="props" />
              </template>
              <!-- pagination -->
              <template
                v-if="paginationEnabled"
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '20', '50']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { VueGoodTable } from "vue-good-table";

export default {
  name: "MyTable",
  components: {
    VueGoodTable,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    columns: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Array,
    },
    isLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    paginationEnabled: {
      type: Boolean,
      default: true,
    },
    searchEnabled: {
      type: Boolean,
      default: false,
    },
    hasExport: {
      type: Boolean,
      default: false,
    },
    exporting: {
      type: Boolean,
      default: false,
    },
    exportType: {
      type: Array,
      default: () => [{ value: "csv", text: "CSV" }],
    },
  },
  data() {
    return {
      search: "",
      pageLength: 10,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
